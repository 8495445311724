import './beneficiosCard.css'

function BeneficiosCard({title, subtitle, img, content, col}) {

    return (
        <div className={`BeneficiosCard ${col ? "col-lg-3 col-md-4 col-sm-12" : null}`}>
            <div className={`card ${col ? "card-beneficios-col" : "card-beneficios"}`}>
                <div className="card-body card-body-beneficios p-2">
                    <div className="card-header-beneficios row">
                        <div className={col ? "col-3" : "col-2"}>
                            <img src={img} width={44} height={43} className="p-0"/>          
                        </div>
                        <div className={`vstack ${col ? "col-9" : "col-10"} p-0`}>
                            <span className={`card-title-beneficios ${col ? "ms-2" : null}`}>{title}</span>
                            <span className={`card-subtitle-beneficios ${col ? "ms-2" : null}`}>{subtitle}</span>
                        </div>                        
                    </div>
                    <div className={`card-contenido-beneficios`}>
                        <p className="m-0">{content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BeneficiosCard;