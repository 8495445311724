import '../home/home.css'
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import BeneficiosCard from '../../components/beneficiosCard/BeneficiosCard';
import iconMaterial from '../../assets/img/iconMaterial.svg'
import iconMedidas from '../../assets/img/iconMedidas.svg'
import iconEntrega from '../../assets/img/iconEntrega.svg'
import iconPresupuesto from '../../assets/img/iconPresupuesto.svg'
import home2 from '../../assets/img/home2.png'
import templadoRoto from '../../assets/img/templadoRoto.png'
import arquitectos from '../../assets/img/arquitectos.png'
import constructores from '../../assets/img/constructores.png'
import armadores from '../../assets/img/armadores.png'

function Home() {

  const navigate = useNavigate();

  return (
  <>
    <Navbar/>
    <div className="Home">
      <div className="container home-container">
        <h1 className="home-title">
            Si eres una empresa debes elegir al mejor <b>partner</b>
        </h1>
        <p className="home-subtitle mt-5">
            Somos el socio estratégico que necesitas para definir las 
            <br/>
            mejores ventanas que llevara tu proyecto.
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-empresa")}>Cubicación gratuita</button>
        </div>
        <div className="text-center mt-5">
          <img className="img-home1 img-fluid" src={home2} alt="diseño interior"/>
        </div>
        <h1 className="second-title">Soluciones de ventanas y puertas para <b>profesionales</b></h1>
        <div className="text-center mt-4">
          <p className="home-subtitle">
            Entendemos que el mundo de la construcción es un desafío constante, 
             <br/>
            queremos ser el partner estratégico que necesitas.
          </p>
        </div>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-empresa")}>Cubica tu proyecto</button>
        </div>
        <div className="container-soluciones">
            <div className="row text-center">
                <div className="col-12 col-lg-4">
                    <img src={arquitectos} className="img-fluid img-soluciones" />
                    <h6 className="soluciones-title">Arquitectos</h6>
                </div>
                <div className="col-12 col-lg-4">
                    <img src={constructores} className="img-fluid img-soluciones" />
                    <h6 className="soluciones-title">Constructores</h6>
                </div>
                <div className="col-12 col-lg-4">
                    <img src={armadores} className="img-fluid img-soluciones" />
                    <h6 className="soluciones-title">Armadores</h6>
                </div>
            </div>
        </div>
        <h1 className="second-title" id='comoFunciona'>
          Asesoría <b>Profesional</b> gratuita 
        </h1>
        <p className="home-subtitle mt-4">
          Nuestros asesores y consultores trabajaran con usted codo a codo 
            <br/>
          para entender a fondo su proyecto, el experto revisara con usted los siguientes aspectos 
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-empresa")}>Solicita tu asesoría</button>
        </div>
        <div className='row mt-5 gy-4 gx-3'>
          <BeneficiosCard 
            title={"Material"}
            subtitle={"Aluminio, PVC o Ethernity"}
            img={iconMaterial}
            content={"Dependiendo del desafío constructivo y de las necesidades del cliente es que se seleccionara la mejor materialidad, diferenciados en Aluminio, PVC o Ethernity. Adicionalmente seleccionaremos el color adecuado."}
            col={true}
          />
          <BeneficiosCard 
            title={"Medidas"}
            subtitle={"Medición de ventanas"}
            img={iconMedidas}
            content={"En este proceso se realizara la toma de medidas de ventanas. Este proceso se puede hacer remoto y una posterior rectificación de medidas en terreno para evitar errores."}
            col={true}
          />
          <BeneficiosCard 
            title={"Presupuesto"}
            subtitle={"Detallado y especificado"}
            img={iconPresupuesto}
            content={"Al final de la asesoría el cliente tendrá un presupuesto detallado con todo el proyecto, incluyendo las especificaciones, medidas, materialidad y precio."}
            col={true}
          />
          <BeneficiosCard 
            title={"Entrega"}
            subtitle={"Entrega y despacho"}
            img={iconEntrega}
            content={"Se planificara la entrega de material dependiendo de la necesidad de los clientes, ya sea en la fabrica directa del cliente o en obra directamente."}
            col={true}
          />
        </div>
        <h1 className="second-title">
          ¿Tienes un vidrio o un termopanel roto?
        </h1>
        <p className="home-subtitle mt-4">
          Déjanos toda la información necesaria para cotizarte de inmediato y reparar a la brevedad
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-empresa")}>Solicita tu cotización</button>
        </div>
      </div>
    </div>
    <img className="img-fluid img-templado" src={templadoRoto} alt="diseño interior"/>
    <Footer/>
  </>
  );
}

export default Home;