import './form.css'
import HubspotContactForm from '../../components/HubspotContactForm/HubspotContactForm';
import { useNavigate } from 'react-router-dom';
import flechaAzul from '../../assets/img/flechaAzul.svg'
import logoGes from '../../assets/img/logoGes.svg'

function ClienteEmpresaForm() {
    
    const navigate = useNavigate();

  return (
    <main>
      <div className='form-header mt-5 d-md-none'>
        <div className="d-flex align-items-center justify-content-center logo-container-form" onClick={()=>navigate("/")}>
          <img alt="Logo" src={logoGes} />
        </div>
        <p className="svc-text-font mt-4 mx-3">
          Agenda hoy mismo con nuestros
          <br/>
          consultores la fecha para revisar
          <br/>
          el proyecto, realizar la cubicación
          <br/>
          y generar un presupuesto de forma inmediata.
        </p> 
      </div>
      <div className="form-container">
      <div className='form-header-desktop text-center'>
        <div className="logo-container-form" onClick={()=>navigate("/")}>
            <img alt="Logo" src={logoGes}/>
        </div>
        <p className="svc-text-font mt-5 mb-5">
          Agenda hoy mismo con nuestros consultores la fecha para revisar
          <br/>
          el proyecto, realizar la cubicación y generar un 
          <br/>
          presupuesto de forma inmediata
        </p>
      </div>
        <div className="container">
          <HubspotContactForm 
            region="na1"
            portalId="21901438"
            formId='01cbc57a-2ee7-4760-a5c6-5dd52a0b6db6'
          />
        </div> 
      <div className="form-footer mt-2">
        Al solicitar información, estás aceptando los
          <br/> 
        <a className='tc-font'>términos y condiciones</a> de GesChile
      </div>
      <div className='text-center mt-5'>
        <a className='link-element-orange' href="/"><img src={flechaAzul} className="me-2" />Volver al inicio</a>
      </div>
      </div>
    </main> 
  );
}

export default ClienteEmpresaForm;