import { Routes, Route } from "react-router-dom"
import Home from "../views/home/Home";
import IndexIC from "../views/inmobiliariasConstructores/index"
import ClienteFinalForm from "../views/Forms/ClienteFinalForm";
import ClienteEmpresaForm from "../views/Forms/ClienteEmpresaForm";

function AppRoutes() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/inmobiliarias-y-constructoras" element={ <IndexIC/> } />
        <Route path="/form-cliente" element={ <ClienteFinalForm/> } />
        <Route path="/form-empresa" element={ <ClienteEmpresaForm/> } />
      </Routes>
    </div>
  );
}

export default AppRoutes;