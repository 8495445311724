import './navbar.css'
import aExperiencia from '../../assets/img/aExperiencia.svg'
import logoGes from '../../assets/img/logoGes.svg'
import toggler from '../../assets/img/toggler.svg'
import Dropdown from '../dropdown/Dropdown';
import dropdownArrow from '../../assets/img/dropdownArrow.svg';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const [show, setShow] = useState(false);
    const refContainer = useRef(null);
    const formularioContacto = () => {
        if (window.location.href.includes('inmobiliarias-y-constructoras')) return '/form-empresa'
        return '/form-cliente'
    }
    const navigate = useNavigate()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (refContainer.current && !refContainer.current.contains(event.target)) {
                setShow(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [refContainer]);

    return (
        <div className="Navbar">
            <nav className="navbar navbar-expand-lg p-0">
                <div className="container-lg d-lg-flex justify-content-between" ref={refContainer}>
                    <button className="navbar-toggler navbar-toggler-color" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <img alt="abrir menu" src={toggler} className="img-fluid" />
                    </button>
                    <a className="logo-container">
                        <img src={logoGes} alt="Logo" className="img-fluid" />
                    </a>
                    <div className="logo-container d-lg-none">
                    </div>
                    <div className="collapse navbar-collapse d-lg-flex justify-content-evenly" id="navbarSupportedContent">
                        {show ? <Dropdown /> : null}
                        <ul className="navbar-nav mb-2 my-lg-0 text-center">
                            <li className="nav-item navbar-dropdown d-none d-lg-block" onClick={()=>setShow(!show)}>
                                <a className={`nav-link navbar-element ${show ? 'active-color' : null}`}>Productos<img src={dropdownArrow} className="arrow-dropdown ms-2" /></a>
                            </li>
                            <li className="nav-item dropdown d-lg-none">
                                <a className="nav-link navbar-element" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Productos<img src={dropdownArrow} className="arrow-dropdown ms-2" /></a>
                                <ul className="dropdown-menu text-center border-0" aria-labelledby="navbarDropdownMenuLink">
                                    <li><a className="nav-link navbar-element" onClick={() => navigate("/")}>Dueños de casa</a></li>
                                    <li><a className="nav-link navbar-element" onClick={() => navigate("/inmobiliarias-y-constructoras")}>Inmobiliarias y Constructores</a></li>
                                    <li><a className="nav-link navbar-element" href='#comoFunciona' onClick={() => navigate("/inmobiliarias-y-constructoras")}>Armadores</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navbar-element" href="#comoFunciona">Como funciona</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navbar-element" href="https://gesfacil.cl/" target={'_blank'}>Software cubicación</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link navbar-element navigate-link" onClick={() => navigate(formularioContacto())}>Contacto</a>
                            </li>
                        </ul>
                    </div>
                    <div className="rb-container d-none d-lg-flex align-content-center justify-content-end">
                        <img src={aExperiencia} alt="años de experiencia" className="img-fluid"/>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;