import './footer.css'
import f from '../../assets/img/facebook.svg'
import t from '../../assets/img/twitter.svg'
import i from '../../assets/img/instagram.svg'
import logoGes from '../../assets/img/logoGes.svg'
import { useNavigate } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate()

    return (
        <div className="container footer-container">
        <footer className=" px-4 row row-cols-1 row-cols-sm-2 row-cols-md-5 gx-4 py-5 my-5">
          <div className="col mb-3">
            <a className="navbar-brand font-logo-footer p-0">
                <img src={logoGes} alt="Logo" className="me-3"/>
            </a>
            <p className="font-footer mt-2">Chañarcillo 1201, Maipú. Chile</p>
          </div>
          <div className="col mb-3">
            <h5 className="subtitle-footer-font">Productos</h5>
            <ul className="nav flex-column font-footer">
              <li className="nav-item mb-2"><a onClick={() => navigate("/form-cliente")} className="nav-link p-0 text-muted navigate-link">Asesoría en termopaneles</a></li>
              <li className="nav-item mb-2"><a onClick={() => navigate("/form-cliente")} className="nav-link p-0 text-muted navigate-link">Asesoría en cristales</a></li>
            </ul>
          </div>
          <div className="col mb-3">
            <h5 className="subtitle-footer-font">Quienes somos</h5>
            <ul className="nav flex-column font-footer">
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Nuestra historia</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Opiniones</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Trabaja con nosotros</a></li>
            </ul>
          </div>
          <div className="col mb-3">
            <h5 className="subtitle-footer-font">Recursos</h5>
            <ul className="nav flex-column font-footer">
              <li className="nav-item mb-2"><a href="https://gesfacil.cl/" className="nav-link p-0 text-muted" target={'_blank'}>Software Gesfacil</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Planimetría de perfiles</a></li>
            </ul>
          </div>
          <div className="col mb-3">
            <div className="social-logo-f">
                <img src={f} alt="Logo"/>
            </div>
            <div className="social-logo">
                <img src={t} alt="Logo"/>
            </div>
            <div className="social-logo">
                <img src={i} alt="Logo"/>
            </div>
          </div>
        </footer>
      </div>
    );
}

export default Footer;