import './dropdown.css'
import { useNavigate } from 'react-router-dom';

function Dropdown() {
    const navigate = useNavigate();
    return (
        <div className="Dropdown d-none d-lg-block">
            <div className="dropdown-container">
                <div className="row">
                    <div className="col px-md-0 px-3 col-color">
                        <div className="col-content-container" onClick={()=>navigate("/")}>
                            <p className="dropdown-titulo">Dueños de casa</p>
                            <p className="dropdown-contenido"> Asesorías de ventanas y cristales para proyectos menores.</p>
                        </div>
                    </div>
                    <div className="col px-md-0 px-3 col-color">
                        <div className="col-content-container" onClick={()=>navigate("/inmobiliarias-y-constructoras")}>
                            <p className="dropdown-titulo">Inmobiliarias y Constructores</p>
                            <p className="dropdown-contenido">Cubicación de proyectos para inmobiliarias y constructoras.</p>
                        </div>
                    </div>
                    <div className="col px-md-0 px-3 col-color">
                        <a className="col-content-container" href='#comoFunciona' onClick={()=>navigate("/inmobiliarias-y-constructoras")}>
                            <p className="dropdown-titulo">Armadores</p>
                            <p className="dropdown-contenido"> Cubicación, presupuesto y proyectos para empresas.</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dropdown;