import './home.css'
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import BeneficiosCard from '../../components/beneficiosCard/BeneficiosCard';
import eficiencia from '../../assets/img/eficiencia.svg'
import pluma from '../../assets/img/pluma.svg'
import seguridad from '../../assets/img/seguridad.svg'
import iconMaterial from '../../assets/img/iconMaterial.svg'
import iconMedidas from '../../assets/img/iconMedidas.svg'
import iconFabricacion from '../../assets/img/iconFabricacion.svg'
import iconPresupuesto from '../../assets/img/iconPresupuesto.svg'
import ventanas from '../../assets/img/ventanas.png'
import eleccion from '../../assets/img/eleccion.png'
import presupuesto from '../../assets/img/presupuesto.png'
import consultoria from '../../assets/img/consultoria.png'
import home1 from '../../assets/img/home1.png'
import templadoRoto from '../../assets/img/templadoRoto.png'

function Home() {

  const navigate = useNavigate();

  return (
  <>
    <Navbar/>
    <div className="Home">
      <div className="container home-container">
        <h1 className="home-title d-lg-none">
          Te asesoramos en la elección
            <br/>
          de ventanas para pequeños 
            <br/>
          y grandes proyectos
        </h1>
        <h1 className="home-title d-none d-lg-block">
          Te asesoramos en la elección de ventanas
            <br/>
          para pequeños y grandes proyectos
        </h1>
        <p className="home-subtitle d-none d-lg-block mt-5">
          Encontrar una empresa que te entregue el respaldo y la tranquilidad que las ventanas
           <br/> 
          de tu casa no fallaran es una decisión para toda la vida.
        </p>
        <p className="home-subtitle d-lg-none">
          Encontrar una empresa que te entregue el respaldo y la tranquilidad que las ventanas
           <br/> 
          de tu casa no fallaran es una decisión para toda la vida.
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-cliente")}>Asesoría Gratis</button>
        </div>
        <div className="text-center mt-5">
          <img className="img-home1 img-fluid" src={home1} alt="diseño interior"/>
        </div>
        <div className="icons-container mt-5">
          <div className="row g-4">
            <div className="col-12 col-lg-4 text-center">
              <img src={pluma} className="img-fuid"/>
              <p className="icon-title">Millones de posibilidades</p>
              <p className="icon-subtitle">Todas las posibilidades para el diseño de tu casa</p>
            </div>
            <div className="col-12 col-lg-4 text-center">
              <img src={seguridad} className="img-fuid"/>
              <p className="icon-title">Garantía y respaldo</p>
              <p className="icon-subtitle">Con el respaldo del Holding AlChile</p>
            </div>
            <div className="col-12 col-lg-4 text-center">
              <img src={eficiencia} className="img-fuid"/>
              <p className="icon-title">Tecnología y eficiencia</p>
              <p className="icon-subtitle">Un mundo más sustentable es posible</p>
            </div>
          </div>
        </div>
        <h1 className="second-title">La mayor variedad de colores y aperturas</h1>
        <div className="text-center mt-4">
          <p className="home-subtitle">
            Elegir la correcta será tan fácil como un abrir y cerrar de ventana.
          </p>
        </div>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-cliente")}>Solicita asesoría</button>
        </div>
        <img src={ventanas} className="img-fluid mt-5"/>
        <h1 className="second-title">
          Te asesoramos en todo el proceso y en todo momento 
        </h1>
        <p className="home-subtitle mt-4">
          Desde la materialidad adecuada según tu necesidad, hasta el color, herraje, tipo de cristales y transporte.
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-cliente")}>Agenda tu consultoría</button>
        </div>
        <div className="container-asesoramiento">
          <div className="row g-5">
            <div className="col-12 col-lg-4">
              <div className="card-asesoramiento text-center">
                <img src={consultoria} className="img-fluid" />
                <h6 className="card-title-asesoramiento">Asesoría y consultoría</h6>
                <p className="card-text-asesoramiento">
                  Los proyectos inmobiliarios pueden ser complejos, especialmente cuando se trata de diseñar la instalación de ventanas, cristales y termopaneles. Por esta razón, es recomendable contar con la asesoría de expertos en la materia para garantizar que el proyecto sea diseñado de manera adecuada.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card-asesoramiento text-center">
                <img src={eleccion} className="img-fluid" />
                <h6 className="card-title-asesoramiento">Elección de materiales </h6>
                <p className="card-text-asesoramiento">
                  Nos encargaremos de seleccionar el material más adecuado para las necesidades específicas de tu proyecto inmobiliario, ya sea aluminio tradicional, aluminio con rotura de puente termico (Ethernity) o PVC. Ofrecemos una amplia gama de colores disponibles para que puedas elegir el que mejor se adapte a tus necesidades. 
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card-asesoramiento text-center">
                <img src={presupuesto} className="img-fluid" />
                <h6 className="card-title-asesoramiento">Presupuesto final</h6>
                <p className="card-text-asesoramiento">
                  Tener un presupuesto detallado es necesario, pero contar
                  con un proceso de cubicación profesional es indispensable,
                  esto te hará ahorrar entre un un 5% y un 10% de dinero en tu proyecto.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h1 className="second-title" id='comoFunciona'>
          Asesoría Profesional gratuita 
        </h1>
        <p className="home-subtitle mt-4">
          Nuestros asesores y consultores trabajaran con usted codo a codo 
            <br/>
          para entender a fondo su proyecto, el experto revisara con usted los siguientes aspectos 
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-cliente")}>Solicita tu asesoría</button>
        </div>
        <div className='row mt-5 gy-4 gx-3'>
          <BeneficiosCard 
            title={"Material"}
            subtitle={"Aluminio, PVC o Ethernity"}
            img={iconMaterial}
            content={"Dependiendo del desafío constructivo y de las necesidades del cliente es que se seleccionara la mejor materialidad, diferenciados en Aluminio, PVC o Ethernity. Adicionalmente seleccionaremos el color adecuado."}
            col={true}
          />
          <BeneficiosCard 
            title={"Medidas"}
            subtitle={"Medición de ventanas"}
            img={iconMedidas}
            content={"En este proceso se realizara la toma de medidas de ventanas. Este proceso se puede hacer remoto y una posterior rectificación de medidas en terreno para evitar errores."}
            col={true}
          />
          <BeneficiosCard 
            title={"Fabricación"}
            subtitle={"Explicación de proceso"}
            img={iconFabricacion}
            content={"Se explicara en detalle cual será el flujo de la  fabricación del proyecto, se estipularan los tiempos aproximados, y se establecerán los hitos esperados, tanto en la fabricación, entrega y recepción de los productos."}
            col={true}
          />
          <BeneficiosCard 
            title={"Presupuesto"}
            subtitle={"Detallado y especificado"}
            img={iconPresupuesto}
            content={"Al final de la asesoría el cliente tendrá un presupuesto detallado con todo el proyecto, incluyendo las especificaciones, medidas, materialidad y precio."}
            col={true}
          />
        </div>
        <h1 className="second-title">
          ¿Tienes un vidrio o un termopanel roto?
        </h1>
        <p className="home-subtitle mt-4">
          Déjanos toda la información necesaria para cotizarte de inmediato y reparar a la brevedad
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("/form-cliente")}>Solicita tu cotización</button>
        </div>
      </div>
    </div>
    <img className="img-fluid img-templado mb-lg-5" src={templadoRoto} alt="diseño interior"/>
    <Footer/>
  </>
  );
}

export default Home;